.card-collection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 80%;
    max-width: 800px;
    margin: auto;
}

.my-survey-container {
    width: 80%;
    margin: auto;
    max-width: 600px;
}

.card-container {
    margin-top: 100px;
    will-change: transform;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: url('../cursor.png') 39 39, auto;
}

.my-survey-container {
    width: 80%;
    margin: auto;
    max-width: 600px;
}

.login-container {
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
    background-color: #f4f4f4;
    border-radius: 20px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.card {
    background-color: #ffffff;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 225px;
    max-width: 450px;
    max-height: 612px;
    will-change: transform;
    border-radius: 10px;
    margin-bottom: 24px;
    position: relative;
    top: 0;
    transition: top ease 0.2s;
    cursor: pointer;
    /* cursor: url('../cursor.png') 15 15, auto; */
}

.card:hover {
    top: -10px;
}

.card-title {
    font-family: 'Brandon Grotesque';
    src: url("../fonts/Brandon_blk.otf");
    font-size: 15px;
    text-align: center;
    letter-spacing: 0.5px;
    width: 100%;
    margin-bottom: 2px;
}

.card-name {
    font-family: 'Brandon Grotesque';
    src: url("../fonts/Brandon_blk.otf");
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 4px;
    margin-bottom: 2px;
    letter-spacing: 0.5px;
    width: 100%;
}

.card-locked {
    background-color: white;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 225px;
    max-width: 450px;
    height: 306px;
    max-height: 612px;
    will-change: transform;
    border-radius: 10px;
    margin-bottom: 24px;
}

.selected-item {
    text-align: center;
}

.controls-container {
    --highlight-width: auto;
    --highlight-x-pos: 0;

    display: flex;
    margin: 20px 0 25px;
}

.controls {
    display: inline-flex;
    justify-content: space-between;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    max-width: 500px;
    padding: 12px;
    margin: auto;
    overflow: hidden;
    position: relative;
}

.controls input {
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 100%;
}

.segment {
    /* width: 100%; uncomment for each segment to have matching width */
    min-width: 120px;
    position: relative;
    text-align: center;
    z-index: 1;
}

.segment.active {
    /* width: 100%; uncomment for each segment to have matching width */
    background-color: #000000;
    border-radius: 20px;
}

.segment label {
    cursor: pointer;
    display: block;
    font-weight: 700;
    padding: 10px 30px;
    transition: color 0.5s ease;
}

.segment.active label {
    color: #fff;
}

.controls::before {
    content: "";
    background: #000000;
    border-radius: 8px;
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 0;
    z-index: 0;
}

/* Only allow transitions once component is ready */
.controls.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
}

.gold-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay-content {
    background: #fff;
    padding: 20px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    border-radius: 8px;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
}

.scrollable-content {
    max-height: 100%;
    overflow-y: auto;
}