.account {
    width: 100%;
    height: 100%;
}
.survey-page-container {
    padding: 16px 24px;
}

.account-logout-btn {
    font-size: 18px;
    color: red;
    cursor: pointer;
}
.account-logout-btn:hover {
    color: darkred;
}
.dashboard div {
    margin-top: 7px;
}

.survey-title {
    font-family: 'Brandon Grotesque', 'Inter';
    letter-spacing: 1.1px;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/Brandon_bld.otf')
  }

.survey-subtitle {
    font-weight: 500;
    margin:auto;
}

.survey-question {
    font-weight: 600;
    padding-top: 16px;
    margin:auto;
}

.survey-container {
    padding: 20px 0px;
    display: flex;
}

@media (max-width: 540px) {
    .survey-container {
        display: flex;
    }
}

/* @media (max-width: 540px) {
    .submit-btn {
        background-color: #D1DFEE;
        color: #1961A8;
        text-align: center;
        font-weight: 600;
        padding-top: 12px;
        padding-bottom: 12px;
        width: 80%;
        margin-bottom: 8px;
        display: block;
        cursor: pointer;
        border-radius: 12px;
        transition: 0.2s cubic-bezier(0.44, 0.13, 0.48, 0.87);
    }
} */

.survey-value {
    padding-top: 16px;
    padding-bottom: 32px;
    text-align: center;
    margin: auto;
}