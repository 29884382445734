/* container */
.responsive-three-column-grid {
    display:block;
}

/* columns */
.responsive-three-column-grid > * {
    padding:1rem;
}

/* container */
.padded-boxes {
    display:flex;
    flex-wrap:wrap;
    justify-content: space-between;
    /* gap:2rem; */
}

/* boxes */
.padded-boxes > * {
    width:100%;
    background:#eee;
    border-radius:.4rem;
}

/* headings */
.padded-boxes .heading {
    background:#f97171;
    margin:0;
    padding:4px;
    /* border-top-left-radius:.4rem;
    border-top-right-radius:.4rem; */
}

.basic-heading {
    background: #268fff;
}

.gold-heading {
    background: #FFC642;
}

.platinum-heading {
    background: #525252;
}

/* padded content */
.padded-boxes .padded {
    padding:.1rem 1rem;
}

/* tablet breakpoint */
@media (min-width:768px) {
    .padded-boxes > * {
        width:calc((100% - 4rem) / 3);
        gap: 2em;
    }
}

/* tablet breakpoint */
@media (min-width:768px) {
    .responsive-three-column-grid {
        display: grid;
        grid-auto-rows: 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

/* container */
feature-compare {
    display:flex;
    margin-top:2.5em;
}

/* columns */
feature-compare > * {
    position:relative;
    padding:0;
    text-align:center;
    width:25%;
}
feature-compare > :nth-child(2) {
    width:50%;
    z-index:10;
    box-shadow:0 5px 10px rgba(0, 0, 0, 25%);
}
feature-compare > :nth-child(2):before {
    content:"Most Popular";
    display:block;
    position:absolute;
    top:-2em;
    left:0;
    right:0;
    line-height:2em;
    background:#875E00;
    color:#fff;
    font-weight: 600;
    border-top-left-radius:.4em;
    border-top-right-radius:.4em;
}
free-level {
    background:#268fff;
    border-radius: 10px 0px 0px 10px;
}
standard-level {
    background:#FFC642;
}
pro-level {
    background:#C65447;
    border-radius: 0px 10px 10px 0px;
}

/* features */
feature-compare ul {
    list-style:none;
    margin:0;
    padding:0;
    background:#fff;
}
feature-compare li {
    white-space:nowrap;
    line-height:2.2em;
    border-top:1px solid #eee;
}
feature-compare li:first-child {
    border-top:0;
}
feature-compare li.tick:before {
    content: '✓ ';
    color:#006e37;
    font-weight:bold;
    font-size:1.3em;
}
feature-compare li.cross:before {
    content: '✗ ';
    color:#ec1358;
    font-weight:bold;
    font-size:1.3em;
}
feature-compare h3 {
    margin:0;
    padding:.4em 0 0;
}
feature-compare .price {
    margin:0 0 .2em;
    font-size:2em;
    font-weight:bold;
}
feature-compare span {
    display:none;
}

/* buttons */
feature-compare .button {
    display:inline-block;
    margin:.5em 0;
    padding:.4em .5em;
    background:#fff;
    color:#000;
    text-decoration:none;
    font-weight:bold;
    border-radius:.4em;
}
feature-compare > :nth-child(2) .button {
    background:#000;
    color:#fff;
}

/* tablet breakpoint */
@media (min-width:900px) {
    feature-compare {
        margin:2.5em calc(8px + 1.5625vw);
    }
    feature-compare > * {
        width:calc(100% / 3);
    }
    feature-compare span {
        display:inline;
    }
}