.hello-player {
    width: 150px;
}
  
.my-survey-container {
    width: 80%;
    margin: auto;
    max-width: 600px;
}

.survey-score-container {
    display: flex;
    justify-content: space-between;
    padding: 8px 32px;
    background-color: #f4f4f4;
    border-radius: 20px;
    margin-bottom: 24px;
}

.survey-single-player-container {
    justify-content: flex-start;
    padding-top: 8px;
    padding-bottom: 16px;
    padding-right: 32px;
    background-color: #1961A835;
    border-radius: 20px;
    margin-bottom: 24px;
    flex-direction: column;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.survey-team-survey-container {
    justify-content: flex-start;
    padding-top: 8px;
    padding-bottom: 16px;
    padding-left: 32px;
    background-color: #509E6F35;
    border-radius: 20px;
    margin-bottom: 24px;
    flex-direction: column;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.survey-text-container {
    flex-direction: column;
    margin-bottom: 8px;
}

.subtitle-text {
    color: gray;
    font-weight: 400;
}

.button-text {
    color: #1961A8;
    cursor: pointer;
    font-weight: 600;
    font-size: large;
    text-decoration: none;
}

.button-text:hover {
    color: #333333;
}

.score-title {
    text-align: start;
    color: #000000;
    font-weight: 600;
}

.score-text {
    text-align: start;
    color: gray;
    font-weight: 400;
}

.survey-title {
    font-family: 'Brandon Grotesque';
    src: url("../fonts/Brandon_blk.otf");
    letter-spacing: 1.1px;
    font-weight: 400;
}

.survey-score {
    font-family: 'Brandon Grotesque';
    src: url("../fonts/Brandon_blk.otf");
    letter-spacing: 1.1px;
    text-align: center;
    font-size: 80px;
}